import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.AUTH_SERVER_PREFIX}/dashboard`
const endpoints = {
    totalUsers: () => `${RESOURCE_ENDPOINT}/total-user`,
    activeUsers: () => `${RESOURCE_ENDPOINT}/active-user`,
    countUserByCountry: () => `${RESOURCE_ENDPOINT}/count-user-by-country`,
    userStats: () => `${RESOURCE_ENDPOINT}/user-stats`,
    userAnalytics: () => `${RESOURCE_ENDPOINT}/user-analytics`,
}

export default class AuthDashboardApi {
    public getTotalUsers = (): AxiosPromise<any> => {
        const url = endpoints.totalUsers();
        return HttpService.get(url);
    }

    public getActiveUsers = (): AxiosPromise<any> => {
        const url = endpoints.activeUsers();
        return HttpService.get(url);
    }

    public getCountryWiseUserList = (): AxiosPromise<any> => {
        const url = endpoints.countUserByCountry();
        return HttpService.get(url);
    }

    public getUserStats = (): AxiosPromise<any> => {
        const url = endpoints.userStats();
        return HttpService.get(url);
    }

    public getUserAnalytics = (): AxiosPromise<any> => {
        const url = endpoints.userAnalytics();
        return HttpService.get(url);
    }

}