import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.min.css";
import "./assets/css/custom.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { debugContextDevtool } from "react-context-devtool";
const container = document.getElementById("root");
ReactDOM.render(
  // <React.StrictMode>
  <App />,
  //  </React.StrictMode>,
  container
);
debugContextDevtool(container);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
